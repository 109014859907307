import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

export default function ActionAreaCard2() {
  return (
    <Card sx={{ maxWidth: 445 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          width='160'
          src='https://gumlet.assettype.com/evoindia%2F2020-06%2Fa808772b-79e6-4086-9a33-5fae88a29ae0%2FTriumph_TrekkerGT_Prototype_2020_GE_7J9A9721__1_.jpg?rect=0%2C199%2C2048%2C1152&auto=format%2Ccompress&fit=max&format=webp&w=480&dpr=2.6'
          alt="xiaomi amg scooter"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Bicycles on stock quantity:
          </Typography>
          <Typography variant="body2" color="text.secondary">
          • Red x10 <br></br>
          • Blue  x5 <br></br>
          • Green x7
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}