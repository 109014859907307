import { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import { Button, MenuItem } from "@mui/material";
import TextField from '@mui/material/TextField';
import Header from "../../components/Header";
import '../rental/index.css'


function RentalForm() {
  const [rentals, setRentals] = useState([]);
  const [currentTime, setCurrentTime] = useState(new Date());


  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);


  const renderListTitle = (index) => {
    const listTitle = `Invoice  #${index + 1}`;
    return <h2>{listTitle}</h2>;
  };


  useEffect(() => {
    const savedRentals = JSON.parse(localStorage.getItem("rentals")) || [];
    setRentals(savedRentals);
  }, []);

  useEffect(() => {
    localStorage.setItem("rentals", JSON.stringify(rentals));
  }, [rentals]);

  const addRental = (scooter, worker, location, date, hour, rentingTime, progress) => {
    const newRental = { scooter, worker, location, date, hour, rentingTime, progress };
    setRentals([...rentals, newRental]);

  };


  const deleteRental = (index) => {
    const updatedRentals = rentals.filter((_, i) => i !== index);
    setRentals(updatedRentals);
  };
  

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-around" alignItems="center">
        <Box mr='35px' display="flex" justifyContent="center" alignItems="center">
          <Header title="Renting" subtitle="RENTSCOOT renting process" display="flex" justifyContent="center" alignItems="center" />
        </Box>
      </Box>
      <form onSubmit={(e) => {
        e.preventDefault();
        const scooter = e.target.scooter.value;
        const worker = e.target.worker.value;
        const location = e.target.location.value;
        const date = e.target.date.value;
        const hour = e.target.hour.value;
        const rentingTime = e.target.rentingTime.value;
        addRental(scooter,worker, location, date, hour, rentingTime);
        e.target.reset();
      }}>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Box
            sx={{
              '& .MuiTextField-root': { m: 1, width: '100%' },
            }}
            noValidate
            autoComplete="off"
            display="flex" justifyContent="space-between" 
            flexDirection='column'
          >
            <TextField
              required
              id="outlined-required"
              label="Scooter Number"
              defaultValue=""
              name="scooter"
              type="text"
              color="success"
            />
            <TextField
              required
              id="outlined-required"
              label="Worker"
              defaultValue=""
              name="worker"
              type="text"
              color="success"
            />
            <TextField
              required
              id="outlined-required"
              label="Location"
              defaultValue=""
              name="location"
              type="text"
              color="success"
              select
            > 
              <MenuItem value="Orange">Orange</MenuItem>
              <MenuItem value="Aleja">Aleja</MenuItem> 
            </TextField>
          
            <TextField
              required
              id="outlined-required"
              label="Date"
              defaultValue={new Date().toISOString().slice(0, 10)}
              name="date"
              type="text"
              color="success"
            />
            <TextField
              required
              id="outlined-required"
              label="Current Time"
              defaultValue=""
              name="hour"
              type="text"
              color="success"
              value={currentTime.toLocaleTimeString()}
            />
            <TextField
              required
              id="outlined-required"
              label="Renting Time"
              defaultValue="time"
              name="rentingTime"
              type="text"
              color="success" 
              select
            >
              <MenuItem value="30 minutes">30 minutes</MenuItem>
              <MenuItem value="1 hour">1 hour</MenuItem>
              <MenuItem value="1:30 hour">1:30 hour</MenuItem>
              <MenuItem value="2 hours">2 hours</MenuItem>
              <MenuItem value="3 hours">3 hours</MenuItem>
            </TextField>
            <Box mt="20px" display="flex" justifyContent="end" alignItems="center">
              <Button variant="contained" type="submit" color="success" >Add rent</Button>
            </Box>

            <Box m="20px">
              <Box display="flex" justifyContent="space-around" alignItems="center" >
                <Header title="Rentals" subtitle='RENTSCOOT current rentals' />
              </Box>
           </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
               }}
            >
          <ul style={{ 
            listStyleType: 'none',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'center',
            flexWrap: 'wrap',
            padding: 0,
          }}>
          {rentals.map((rental, index) => (
            <li 
              key={index}
              style={{
                backgroundColor: '#141b2d',
                borderRadius: '8px',
                padding: '16px',
                margin: '8px',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                border: '2px solid #2ec4b6',
                transition: 'all 0.3s ease-in-out',
                cursor: 'pointer',
              }}
            >
            <div style={{ flexGrow: 1 }}>
            <div style={{ fontWeight: 'bold', marginBottom: '8px' }}>
                <h3 style={{ fontWeight: 'bold', fontFamily: 'Arial, sans-serif', fontSize: '18px', color: '#fff' }}>
                {renderListTitle(index)}
                </h3>
              </div>
              <div style={{ fontWeight: 'bold', marginBottom: '8px' }}>
                <h3 style={{ fontWeight: 'bold', fontFamily: 'Arial, sans-serif', color: '#fff' }}>
                  Scooter number: {rental.scooter}
                </h3>
              </div>
              <div style={{ fontWeight: 'bold', marginBottom: '8px' }}>
                <h3 style={{ fontWeight: 'bold', fontFamily: 'Arial, sans-serif', color: '#fff' }}>
                  Worker: {rental.worker}
                </h3>
              </div>
              <div style={{ fontWeight: 'bold', marginBottom: '8px' }}>
                <h3 style={{ fontWeight: 'bold', fontFamily: 'Arial, sans-serif', color: '#fff' }}>
                  Location: {rental.location}
                </h3>
              </div>
              <div style={{ fontWeight: 'bold', marginBottom: '8px' }}>
                <h3 style={{ fontWeight: 'bold', fontFamily: 'Arial, sans-serif', color: '#fff' }}>
                  Date: {rental.date}
                </h3>
              </div>
              <div style={{ fontWeight: 'bold', marginBottom: '8px' }}>
                <h3 style={{ fontWeight: 'bold', fontFamily: 'Arial, sans-serif', color: '#fff' }}>
                  Current time: {rental.hour}
                </h3>
              </div>
              <div style={{ fontWeight: 'bold', marginBottom: '8px' }}>
                <h3 style={{ fontWeight: 'bold', fontFamily: 'Arial, sans-serif', color: '#fff' }}>
                  Renting Time: {rental.rentingTime}
                </h3>
              </div>
            </div>
          <Button 
           variant="contained" 
           color="error" 
           onClick={() => deleteRental(index)}
       >
        Delete
        </Button>
       </li>
       ))}
        </ul>
        </Box>
      </Box> 
     </Box>
    <Box>
   </Box>
  </form>
</Box>
  )};

  export default RentalForm;