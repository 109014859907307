import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import {Routes, Route} from "react-router-dom";
import Topbar from "./scenes/global/Topbar"
import Dashboard  from "./scenes/dashboard";
import Sidebar  from "./scenes/global/Sidebar";
import Team  from "./scenes/team";
import Contacts from "./scenes/contacts";
import Invoices  from "./scenes/invoices";
import Bar from "./scenes/bar";
import Form  from "./scenes/form";
import Faq  from "./scenes/faq";
import Pie from "./scenes/pie";
import Calendar from "./scenes/calendar";
import RentalForm from "./scenes/rental";
import DailyRentals from "./scenes/daily_rentals";



function App() {
  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
      <div className="app">
       <Sidebar />
        <main className="content">
          <Topbar />
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/team" element={<Team />} />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/invoices" element={<Invoices />} />
            <Route path="/form" element={<Form />} />
            <Route path="/rental" element={<RentalForm />} />
            <Route path="/daily_rentals" element={<DailyRentals />} />
            <Route path="/calendar" element={<Calendar />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/bar" element={<Bar />} />
            <Route path="/pie" element={<Pie />} />
          </Routes>
        </main>
      </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
