import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataInvoices } from "../../data/mockData";
import Header from "../../components/Header";

const Invoices = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    { field: "date", headerName: "Date", flex: 1 },
    { field: "id", headerName: "Day" },
    { field: "worker", headerName: "Worker", flex: 1 },
    { field: "location", headerName: "Location", flex: 1 },
    { field: "revenue", headerName: "Revenue", flex: 1, cellClassName: "name-column--cell" },
    { field: "cost", headerName: "Cost", flex: 1, cellClassName: "cost-column--cell" },
  ];

  return (
    <Box m="20px">
      <Header title="Invoices" subtitle="Check monthly billings and invoices" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[400],
          },
          "& .cost-column--cell": {
            color: colors.redAccent[400],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.redAccent[800],
            borderBottom: "none",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.redAccent[800],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid checkboxSelection rows={mockDataInvoices} columns={columns} />
      </Box>
    </Box>
  );
};

export default Invoices;
