import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

export default function ActionAreaCard() {
  return (
    <Card sx={{ maxWidth: 445 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          width='160'
          src='https://shop.ctech.ba/bs/wp-content/uploads/2021/04/xiaomi-mi-scooter-pro-2-amg-petronas-verzia.jpg'
          alt="xiaomi amg scooter"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Scooters on stock quantity:
          </Typography>
          <Typography variant="body2" color="text.secondary">
          • Xiaomi m365 x10 <br></br>
          •  Segway ES2  x5 <br></br>
          •  NineBot x7
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}