import { useState, useEffect } from 'react';
import { startOfWeek, endOfWeek, addDays } from 'date-fns';

function WeeklyDates() {
  const [firstDay, setFirstDay] = useState(null);
  const [lastDay, setLastDay] = useState(null);

  useEffect(() => {
    const today = new Date();
    const start = startOfWeek(today);
    const end = endOfWeek(today);

    setFirstDay(start);
    setLastDay(end);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const today = new Date();
      if (today.getDay() === 0) { 
        const start = startOfWeek(addDays(today, 7));
        const end = endOfWeek(addDays(today, 7));

        setFirstDay(start);
        setLastDay(end);
      }
    }, 1000 * 60 * 60); 

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      {firstDay && lastDay && (
        <>
          <div><span>From</span> {firstDay.toLocaleDateString()}</div>
          <div><span> Until</span> {lastDay.toLocaleDateString()}</div>
        </>
      )}
    </div>
  );
}

export default WeeklyDates;