import { tokens } from "../theme";

export const mockDataTeam = [
  {
    id: 1,
    name: "Edi Sipka",
    email: "edisipka@gmail.com",
    age: 27,
    phone: "(062)317-049",
    access: "admin",
  },
  {
    id: 2,
    name: "Kenan Dipa",
    email: "kenandipa@gmail.com",
    age: 35,
    phone: "(421)314-2288",
    access: "founder",
  },
  {
    id: 3,
    name: "Enis Memic",
    email: "enismemic@gmail.com",
    age: 25,
    phone: "(422)982-6739",
    access: "supporter",
  },
  {
    id: 4,
    name: "Imna Sipka",
    email: "imnasipka@gmail.com",
    age: 22,
    phone: "(921)425-6742",
    access: "admin",
  },
  {
    id: 5,
    name: "Alma Al",
    email: "aa@gmail.com",
    age: 22,
    phone: "(421)445-1189",
    access: "worker",
  },
  
];

export const mockDataContacts = [
  {
    id: 1,
    name: "Edi Sipka",
    email: "edisipka@gmail.com",
    age: 27,
    phone: "(665)121-5454",
    address: "Ilidza",
    city: "Sarajevo",
    zipCode: "71000",
    registrarId: 12001,
  },
  {
    id: 2,
    name: "Kenan Dipa",
    email: "kenandipa@gmail.com",
    age: 35,
    phone: "(421)314-2288",
    address: "Ilidza",
    city: "Sarajevo",
    zipCode: "71000",
    registrarId: 12002,
  },
  {
    id: 3,
    name: "Enis Memic",
    email: "enismemic@gmail.com",
    age: 25,
    phone: "(422)982-6739",
    address: "Ilidza",
    city: "Sarajevo",
    zipCode: "71000",
    registrarId: 12003,
  },
  {
    id: 4,
    name: "Imna Sipka",
    email: "imnasipka@gmail.com",
    age: 22,
    phone: "(921)425-6742",
    address: "Ilidza",
    city: "Sarajevo",
    zipCode: "71000",
    registrarId: 12004,
  },
  {
    id: 5,
    name: "Alma Al",
    email: "aa@gmail.com",
    age: 22,
    phone: "(421)445-1189",
    address: "Ilidza",
    city: "Sarajevo",
    zipCode: "71000",
    registrarId: 12005,
  },
  
];

export const mockDataInvoices = [
  {
    id: 'Mon',
    worker: "Edi Sipka",
    revenue: "120 KM",
    cost: "20 KM",
    location: "Aleja",
    date: "01/03/2023",
  },
  {
    id: 'Tue',
    worker: "Edi Sipka",
    revenue: "130 KM",
    cost: "30 KM",
    location: "Orange",
    date: "02/03/2023",
  },
  {
    id: 'Wed',
    worker: "Kenan Dipa",
    revenue: "140 KM",
    cost: "20 KM",
    location: "Orange",
    date: "03/03/2023",
  },
 
  
];

export const mockDataRentals = [
  {
    id: 'Mon',
    worker: "Edi Sipka",
    scooter: "13",
    bicycle: "22",
    location: "Aleja",
    date: "01/03/2023",
    total: '200'
  },
  {
    id: 'Tue',
    worker: "Edi Sipka",
    scooter: "16",
    bicycle: "30",
    location: "Orange",
    date: "02/03/2023",
    total: '200'
  },
  {
    id: 'Wed',
    worker: "Kenan Dipa",
    scooter: "11",
    bicycle: "20",
    location: "Orange",
    date: "03/03/2023",
    total: '200'
  }
  
];

export const mockTransactions = [
  {
    txId: "Edi",
    location: "Orange",
    date: "2021-09-01",
    profit: "43.95",
  },
  {
    txId: "Keno",
    location: "Aleja",
    date: "2022-04-01",
    profit: "133.45",
  },
  {
    txId: "Edi",
    location: "Orange",
    date: "2021-09-01",
    profit: "43.95",
  },
  {
    txId: "Keno",
    location: "Aleja",
    date: "2022-11-05",
    profit: "200.95",
  },
  {
    txId: "Edi",
    location: "Aleja",
    date: "2022-11-02",
    profit: "13.55",
  },
  {
    txId: "Keno",
    location: "Orange",
    date: "2021-09-01",
    profit: "43.95",
  },
  {
    txId: "Edi",
    location: "Aleja",
    date: "2019-04-15",
    profit: "24.20",
  },
  {
    txId: "Keno",
    location: "Orange",
    date: "2022-04-01",
    profit: "133.45",
  },
  {
    txId: "Edi",
    location: "Aleja",
    date: "2022-04-01",
    profit: "133.45",
  },
];

export const mockInvestment = [
  {
    bio: "Segway ES2",
    location: "Orange",
    date: "2021-09-01",
    cost: "42",
  },
  {
    bio: "Xiaomi X5",
    location: "Aleja",
    date: "2022-04-01",
    cost: "43",
  },
  {
    bio: "Rent",
    location: "Orange",
    date: "2021-09-01",
    cost: "48",
  },
  {
    bio: "Salaries",
    location: "Aleja",
    date: "2022-11-05",
    cost: "49",
  },
  {
    bio: "Tax",
    location: "Aleja",
    date: "2022-11-02",
    cost: "50",
  },
];

export const mockBarData = [
  {
    day: "Mon",
    "100": 137,
    "100Color": "hsl(229, 70%, 50%)",
    '200': 96,
    '200Color': "hsl(296, 70%, 50%)",
    '300': 72,
    '300Color': "hsl(97, 70%, 50%)",
    "400": 140,
    '400Color': "hsl(340, 70%, 50%)",
  },
  {
    day: "Tue",
    "100": 55,
    "100Color": "hsl(307, 70%, 50%)",
    '200': 28,
    '200Color': "hsl(111, 70%, 50%)",
    '300': 58,
    '300Color': "hsl(273, 70%, 50%)",
    "400": 29,
    '400Color': "hsl(275, 70%, 50%)",
  },
  {
    day: "Wed",
    "100": 109,
    "100Color": "hsl(72, 70%, 50%)",
    '200': 23,
    '200Color': "hsl(96, 70%, 50%)",
    '300': 34,
    '300Color': "hsl(106, 70%, 50%)",
    "400": 152,
    '400Color': "hsl(256, 70%, 50%)",
  },
  {
    day: "Thu",
    "100": 133,
    "100Color": "hsl(257, 70%, 50%)",
    '200': 52,
    '200Color': "hsl(326, 70%, 50%)",
    '300': 43,
    '300Color': "hsl(110, 70%, 50%)",
    "400": 83,
    '400Color': "hsl(9, 70%, 50%)",
  },
  {
    day: "Fri",
    "100": 81,
    "100Color": "hsl(190, 70%, 50%)",
    '200': 80,
    '200Color': "hsl(325, 70%, 50%)",
    '300': 112,
    '300Color': "hsl(54, 70%, 50%)",
    "400": 35,
    '400Color': "hsl(285, 70%, 50%)",
  },
  {
    day: "Sat",
    "100": 66,
    "100Color": "hsl(208, 70%, 50%)",
    '200': 111,
    '200Color': "hsl(334, 70%, 50%)",
    '300': 167,
    '300Color': "hsl(182, 70%, 50%)",
    "400": 18,
    '400Color': "hsl(76, 70%, 50%)",
  },
  {
    day: "Sun",
    "100": 80,
    "100Color": "hsl(87, 70%, 50%)",
    '200': 47,
    '200Color': "hsl(141, 70%, 50%)",
    '300': 158,
    '300Color': "hsl(224, 70%, 50%)",
    "400": 49,
    '400Color': "hsl(274, 70%, 50%)",
  },
];

export const mockPieData = [
  {
    id: "May",
    label: "May",
    value: 239,
    color: "hsl(104, 70%, 50%)",
  },
  {
    id: "Jun",
    label: "Jun",
    value: 170,
    color: "hsl(162, 70%, 50%)",
  },
  {
    id: "Jul",
    label: "Jul",
    value: 322,
    color: "hsl(291, 70%, 50%)",
  },
  {
    id: "Aug",
    label: "Aug",
    value: 503,
    color: "hsl(229, 70%, 50%)",
  },
  {
    id: "Sept",
    label: "Sept",
    value: 584,
    color: "hsl(344, 70%, 50%)",
  },
];




