import { Box, useTheme } from "@mui/material";
import Header from "../../components/Header";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tokens } from "../../theme";

const FAQ = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box m="20px">
      <Header title="Shifts and rules" subtitle="Shifts and rules" />

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.redAccent[800]} variant="h5">
            Working hours
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            From Monday to Saturday from 5pm until 12am on two locations:
            Orange and Aleja Park
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.redAccent[800]} variant="h5">
            Dress Code and devices
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Dress casually with brand T-Shirt. <br />
            All devices must be 100% charged every new shift day. <br />
            All rents must be submitted on sheet with specific time of date rented.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.redAccent[800]} variant="h5">
            Weekly invoices
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Every Sunday should report weekly financial balance with all revenues and costs.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.redAccent[800]} variant="h5">
            Salaries
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Every week or after working shift employer should receive salary.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.redAccent[800]} variant="h5">
            Devices
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Currently devices are: <br />
            Xiaomi 365 x10 on Orange location <br />
            Segway ES1 x10 on Aleja location <br />
            Chi bicycle x10 on Aleja location
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default FAQ;
