import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';

function CurrentTime() {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <p>Time: {format(currentTime, 'hh:mm:ss a')}</p>
      <p>Date: {format(currentTime, 'MMMM dd, yyyy')}</p>
    </div>
  );
}

export default CurrentTime;
